import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-5.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import swica1 from "../../assets/images/sp/5/swica-1.jpg";
import swica2 from "../../assets/images/sp/5/swica-2.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`SWICA | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 5</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.swica.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">SWICA Direzione regionale di
                                    Bellinzona</h6>
                                <h1>Benvenuti presso SWICA Bellinzona</h1>
                                <p>Che sia per privati o per aziende: il team si tiene volentieri a vostra disposizione,
                                    assicurandovi un'assistenza competente e personalizzata. Non esitate a contattarci,
                                    saremo lieti di potervi aiutare.</p>
                                <Divider/>
                                <h6 className="fw-bold text-primary">Viale Stazione 28a, 6501 Bellinzona</h6>
                                <h6 className="fw-bold mb-4">Telefono: +41 91 821 45 45</h6>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={swica1} alt="SWICA"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Concorso – Per il Ticino</h6>
                                <h2>100 premi in palio per una pausa di salute</h2>
                                <p>Muoversi e fare attività all’aperto in mezzo alla natura è divertente e favorisce la
                                    forma fisica e mentale a lungo termine. SWICA, più di ogni altra assicurazioni
                                    malattia, sostiene oltre 100 offerte per la prevenzione e la salute. Perché la
                                    salute è tutto. Insieme a Svizzera Turismo, SWICA mette in palio 100 premi che vi
                                    fanno bene.
                                    Aggiudicatevi un fine settimana rigenerante, buoni per l’acquisto di attrezzature
                                    sportive, bastoni da trekking e molto altro. Vi aspettano premi per un valore
                                    complessivo di oltre 8'200 franchi.</p>
                                <a href="https://www.swica.ch/it/su-swica/concorso" target="_blank">
                                    <Button type="primary" className="mb-4">Visita il sito</Button>
                                </a>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}}>
                                <img src={swica2} alt="SWICA"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
